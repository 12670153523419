import React, { useState } from "react"; // Importar React y useState para el manejo del estado
import { jsPDF } from "jspdf"; // Importar jsPDF para generar PDFs
import html2canvas from "html2canvas"; // Importar html2canvas para capturar contenido HTML
import Invoice from "./Invoice"; // Importar el componente Invoice para mostrar la factura

// Componente Checkout: Maneja el proceso de pago y generación de facturas
function Checkout({ cart, clearCart }) {
  // Estados para almacenar el nombre, email y la factura generada
  const [name, setName] = useState(""); 
  const [email, setEmail] = useState(""); 
  const [invoice, setInvoice] = useState(null); 

  // Manejar el proceso de checkout y generar detalles de la factura
  const handleCheckout = () => {
    const invoiceDetails = {
      buyer: { name, email }, // Detalles del comprador
      items: cart.map((item) => ({
        id: item.id,
        name: item.name,
        price: item.price,
        quantity: item.quantity, // Aseguramos que la cantidad esté incluida
      })),
    };
    setInvoice(invoiceDetails); // Guardar los detalles de la factura en el estado
    clearCart(); // Limpiar el carrito después de procesar el checkout
  };

  // Generar un PDF a partir del contenido de la factura
  const generatePDF = () => {
    const input = document.getElementById("invoice-content"); // Obtener el elemento que contiene la factura
    html2canvas(input).then((canvas) => { // Capturar el contenido como imagen usando html2canvas
      const imgData = canvas.toDataURL("image/png"); // Convertir el canvas a imagen PNG
      const pdf = new jsPDF(); // Crear una nueva instancia de jsPDF
      const imgWidth = 190; // Ancho de la imagen en el PDF
      const pageHeight = pdf.internal.pageSize.height; // Altura de la página del PDF
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calcular la altura de la imagen en relación a su ancho
      let heightLeft = imgHeight; // Espacio restante en la página para la imagen

      let position = 0; // Posición inicial de la imagen en la página

      // Añadir la imagen de la factura al PDF
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight); 
      heightLeft -= pageHeight; // Actualizar el espacio restante

      // Manejo de múltiples páginas en el PDF si la imagen es más alta que una página
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight; // Calcular la nueva posición para la siguiente página
        pdf.addPage(); // Añadir una nueva página al PDF
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight); // Añadir la imagen a la nueva página
        heightLeft -= pageHeight; // Actualizar el espacio restante
      }

      pdf.save("invoice.pdf"); // Descargar el PDF generado
    });
  };

  // Función para limpiar los datos de la factura y el carrito
  const clearInvoiceData = () => {
    setName("");       // Reiniciar el campo de nombre
    setEmail("");      // Reiniciar el campo de email
    setInvoice(null);  // Reiniciar la factura
    clearCart();       // Limpiar el carrito
  };

  return (
    <div>
      <div className="mb-3">
        {/* Campo de entrada para el nombre */}
        <input 
          type="text" 
          className="form-control" 
          placeholder="Name" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          required 
        />
      </div>
      <div className="mb-3">
        {/* Campo de entrada para el email */}
        <input 
          type="email" 
          className="form-control" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
      </div>
      {/* Botón para finalizar la compra */}
      <button 
        className="btn btn-success" 
        onClick={handleCheckout}
      >
        Finish Purchase
      </button>

      {/* Si hay una factura generada, mostrarla junto con el botón para descargarla */}
      {invoice && (
        <div className="mt-4">
          <Invoice invoice={invoice} onClear={clearInvoiceData} /> {/* Mostrar la factura generada */}
          <button 
            className="btn btn-primary mt-2" 
            onClick={generatePDF} // Botón para generar el PDF de la factura
          >
            Download Invoice
          </button>
        </div>
      )}
    </div>
  );
}

export default Checkout; // Exportar el componente Checkout para su uso en otras partes de la aplicación

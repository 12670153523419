import React from "react";

// Componente Cart: Muestra los productos en el carrito de compras y permite al usuario limpiar el carrito.
function Cart({ cart, clearCart }) {
  // Calcula el total del carrito sumando el valor total de cada item.
  const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <div>
      <h2 className="text-center">Carrito de compras</h2>
      {/* Tabla para mostrar los items en el carrito */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Item</th> {/* Encabezado para el nombre del producto */}
            <th>Cantidad</th> {/* Encabezado para la cantidad del producto */}
            <th>Precio Unitario</th> {/* Encabezado para el precio unitario */}
            <th>Valor Total</th> {/* Encabezado para el valor total del producto */}
          </tr>
        </thead>
        <tbody>
          {/* Mapeo de los items en el carrito para mostrarlos en la tabla */}
          {cart.map((item, index) => {
            const itemTotalValue = item.price * item.quantity; // Calcula el valor total del item
            return (
              <tr key={index}> {/* Usar el índice como clave para cada fila */}
                <td>{item.name}</td> {/* Nombre del producto */}
                <td>{item.quantity}</td> {/* Cantidad del producto */}
                <td>${item.price.toFixed(2)}</td> {/* Precio unitario del producto, formateado a dos decimales */}
                <td>${itemTotalValue.toFixed(2)}</td> {/* Valor total del producto, calculado y formateado a dos decimales */}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Muestra el total del carrito, formateado a dos decimales */}
      <h3 className="text-end">Total Carrito: ${total.toFixed(2)}</h3>

      <div className="text-end">
        {/* Botón para limpiar el carrito, llama a la función clearCart cuando se hace clic */}
        <button className="btn btn-danger" onClick={clearCart}>
          Clear Cart
        </button>
      </div>
    </div>
  );
}

export default Cart; // Exporta el componente Cart para su uso en otras partes de la aplicación

import React, { useState } from "react"; // Importar React y useState

// Componente ProductList: Muestra la lista de productos y permite acciones sobre ellos
function ProductList({ products, updateProduct, deleteProduct, addToCart, setEditingProduct }) {
  const [quantities, setQuantities] = useState({}); // Para almacenar las cantidades seleccionadas por producto

  // Manejar el cambio de cantidad para un producto específico
  const handleQuantityChange = (id, value) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: value, // Actualiza la cantidad para el producto correspondiente
    }));
  };

  // Manejar la adición de un producto al carrito
  const handleAddToCart = (product) => {
    const quantity = parseInt(quantities[product.id]) || 1; // Toma la cantidad seleccionada o 1 por defecto
    if (quantity > 0 && quantity <= product.stock) {
      addToCart({ ...product, quantity }); // Agrega el producto con la cantidad al carrito
      setQuantities((prev) => ({
        ...prev,
        [product.id]: "", // Reinicia la cantidad después de agregar
      }));
    } else {
      alert("Please select a valid quantity."); // Alerta si la cantidad es inválida
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="table-responsive">
        <table className="table table-striped text-center"> {/* Centrar el texto de la tabla */}
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Stock</th>
              <th>Quantity</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>${product.price.toFixed(2)}</td>
                <td>{product.stock}</td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    min="1"
                    max={product.stock}
                    placeholder="Qty"
                    value={quantities[product.id] || ""} // Mostrar la cantidad seleccionada o vacío
                    onChange={(e) => handleQuantityChange(product.id, e.target.value)} // Manejar el cambio de cantidad
                    style={{ width: "80px", display: "inline-block" }} // Ajustar el ancho del input
                  />
                </td>
                <td>
                  <button 
                    className="btn btn-primary btn-sm me-2" 
                    onClick={() => handleAddToCart(product)} // Manejar la adición al carrito
                  >
                    Add to Cart
                  </button>
                  <button 
                    className="btn btn-secondary btn-sm me-2" 
                    onClick={() => setEditingProduct(product)} // Establecer el producto en edición
                  >
                    Edit
                  </button>
                  <button 
                    className="btn btn-danger btn-sm" 
                    onClick={() => deleteProduct(product.id)} // Manejar la eliminación del producto
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProductList; // Exportar el componente ProductList para su uso en otras partes de la aplicación

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Importar estilos de Bootstrap
import "bootstrap-icons/font/bootstrap-icons.css"; // Importar iconos de Bootstrap
import ProductList from "./components/ProductList"; // Importar el componente ProductList
import ProductForm from "./components/ProductForm"; // Importar el componente ProductForm
import Cart from "./components/Cart"; // Importar el componente Cart
import Checkout from "./components/Checkout"; // Importar el componente Checkout

function App() {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);

  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem("products")) || [];
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setProducts(savedProducts);
    setCart(savedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem("products", JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addProduct = (product) => {
    setProducts([...products, product]);
  };

  const updateProduct = (updatedProduct) => {
    setProducts(
      products.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      )
    );
    setEditingProduct(null);
  };

  const deleteProduct = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const addToCart = (product) => {
    const quantity = parseInt(product.quantity);
    if (quantity > 0 && quantity <= product.stock) {
      const existingProduct = cart.find((item) => item.id === product.id);
      if (existingProduct) {
        if (existingProduct.quantity + quantity <= product.stock) {
          existingProduct.quantity += quantity;
          setCart([...cart]);
        } else {
          alert("No hay suficiente stock para agregar esta cantidad.");
        }
      } else {
        const totalValue = product.price * quantity;
        setCart([...cart, { ...product, quantity, totalValue }]);
      }
    } else {
      alert("Por favor, selecciona una cantidad válida.");
    }
  };

  const clearCart = () => {
    setCart([]);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const totalItemsInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const sortedProducts = [...products].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div
      className={`App container-fluid p-0 ${
        isDarkMode ? "bg-dark text-white" : "bg-light text-dark"
      }`}
    >
      {/* Navbar fija */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <i className="bi bi-shop"></i> E-commerce App
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <button
                  className="btn btn-outline-secondary me-2"
                  onClick={toggleTheme}
                >
                  {isDarkMode ? (
                    <i className="bi bi-sun"></i>
                  ) : (
                    <i className="bi bi-moon"></i>
                  )}
                </button>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#products">
                  Productos
                </a>
              </li>
              <li className="nav-item">
                <a className="btn btn-outline-primary" href="#cart">
                  <i className="bi bi-cart"></i> Carrito{" "}
                  <span className="badge bg-danger">{totalItemsInCart}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Ajustar el contenido para que no quede debajo de la navbar */}
      <div className="container my-5 pt-5">
        <h1 className="text-center mb-4">E-commerce App</h1>

        <div className="row" id="products">
          <div className="col-md-4 mb-4">
            {/* Tarjeta para la sección de productos */}
            <div className="card shadow-sm">
              <div className="card-body">
                <h2 className="card-title text-center">Productos</h2>

                {/* Tarjeta para el formulario de productos */}
                <div className="card shadow-sm mb-4">
                  <div className="card-body">
                    <h3 className="card-title text-center">Agregar Producto</h3>
                    <ProductForm
                      addProduct={addProduct}
                      editingProduct={editingProduct}
                      updateProduct={updateProduct}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 mb-4">
            {/* Tarjeta para la sección de productos */}
            <div className="card shadow-sm">
              <div className="card-body">
                <h2 className="card-title text-center">Productos</h2>

                {/* Tarjeta para la lista de productos */}
                <div className="card shadow-sm">
                  <div className="card-body text-center">
                    <h3 className="card-title">Lista de Productos</h3>
                    <ProductList
                      products={sortedProducts}
                      setEditingProduct={setEditingProduct}
                      deleteProduct={deleteProduct}
                      addToCart={addToCart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tarjeta adicional que envuelve el carrito y checkout */}
        <div className="row" id="cart">
          <div className="col-md-12 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <Cart cart={cart} clearCart={clearCart} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title text-center">Checkout</h2>
                        <Checkout cart={cart} clearCart={clearCart} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="mt-5 text-center">
          <p>
            Gracias a <i className="bi bi-chat-left-text"></i> ChatGPT,{" "}
            <i className="bi bi-robot"></i> Gemini y a todas las LLMs.
          </p>
          <p>
            Especial agradecimiento a mi mentor{" "}
            <i className="bi bi-database text-danger"></i>
            <strong>Juan Carlos Pérez Castillo</strong>.
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App; // Exportar el componente App

import React from "react"; // Importar React para poder crear componentes

// Componente Invoice: Muestra los detalles de la factura generada
function Invoice({ invoice, onClear }) {
  // Calcular el total de la factura sumando el valor total de cada ítem
  const total = invoice.items.reduce((acc, item) => {
    return acc + item.price * item.quantity; // Calcular el total
  }, 0);

  return (
    <div id="invoice-content"> {/* Contenedor principal de la factura */}
      <h3 className="text-center">Invoice</h3> {/* Título de la factura */}
      <p><strong>Name:</strong> {invoice.buyer.name}</p> {/* Mostrar el nombre del comprador */}
      <p><strong>Email:</strong> {invoice.buyer.email}</p> {/* Mostrar el email del comprador */}
      <table className="table table-striped"> {/* Tabla para mostrar los ítems de la factura */}
        <thead>
          <tr>
            <th>Item</th> {/* Encabezado para el nombre del ítem */}
            <th>Cantidad</th> {/* Encabezado para la cantidad */}
            <th>Precio Unitario</th> {/* Encabezado para el precio unitario */}
            <th>Valor Total</th> {/* Encabezado para el valor total por ítem */}
          </tr>
        </thead>
        <tbody>
          {invoice.items.map((item) => ( // Mapear cada ítem en la factura
            <tr key={item.id}> {/* Usar el id del ítem como clave única */}
              <td>{item.name}</td> {/* Mostrar el nombre del ítem */}
              <td>{item.quantity}</td> {/* Mostrar la cantidad del ítem */}
              <td>${item.price.toFixed(2)}</td> {/* Mostrar el precio unitario formateado */}
              <td>${(item.price * item.quantity).toFixed(2)}</td> {/* Valor total por ítem */}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3" className="text-end fw-bold">Total:</td> {/* Total general con estilos de texto */}
            <td className="fw-bold">${total.toFixed(2)}</td> {/* Mostrar el total de la factura formateado */}
          </tr>
        </tfoot>
      </table>
      <div className="text-end">
        <button className="btn btn-danger" onClick={onClear}> {/* Botón para borrar los datos de la factura */}
          Borrar Datos
        </button>
      </div>
    </div>
  );
}

export default Invoice; // Exportar el componente Invoice para su uso en otras partes de la aplicación

import React, { useState, useEffect, useRef } from "react"; // Importar React y hooks useState, useEffect, y useRef

// Componente ProductForm: Maneja la creación y edición de productos
function ProductForm({ addProduct, editingProduct, updateProduct }) {
  // Estados para almacenar los datos del producto
  const [name, setName] = useState(""); // Nombre del producto
  const [price, setPrice] = useState(0); // Precio del producto
  const [stock, setStock] = useState(0); // Stock del producto

  // Crear una referencia para el input del nombre
  const nameInputRef = useRef(null);

  // Efecto para cargar los datos del producto en edición
  useEffect(() => {
    if (editingProduct) {
      // Si hay un producto en edición, carga sus datos
      setName(editingProduct.name);
      setPrice(editingProduct.price);
      setStock(editingProduct.stock);
      nameInputRef.current.focus(); // Mantener el foco en el nombre del producto al editar
    } else {
      // Si no hay producto en edición, resetea los campos
      setName("");
      setPrice(0);
      setStock(0);
      nameInputRef.current.focus(); // Mantener el foco en el nombre del producto después de enviar
    }
  }, [editingProduct]); // Ejecutar el efecto cada vez que cambia editingProduct

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario

    const newProduct = {
      id: editingProduct ? editingProduct.id : Date.now(), // Usar el ID existente si está en edición
      name,
      price: parseFloat(price), // Convertir el precio a un número decimal
      stock: parseInt(stock, 10), // Convertir el stock a un número entero
    };

    if (editingProduct) {
      updateProduct(newProduct); // Actualiza el producto existente
    } else {
      addProduct(newProduct); // Agrega un nuevo producto
    }

    // Resetea los campos después de enviar
    setName("");
    setPrice("");
    setStock("");
    nameInputRef.current.focus(); // Mantener el foco en el nombre del producto después de enviar
  };

  return (
    <form onSubmit={handleSubmit}> {/* Formulario para agregar o editar productos */}
      <div className="mb-3">
        <input 
          type="text" 
          className="form-control" 
          placeholder="Nombre del Producto" 
          value={name} 
          onChange={(e) => setName(e.target.value)} // Actualiza el nombre del producto
          required // Campo obligatorio
          ref={nameInputRef} // Asignar la referencia al input
        />
      </div>
      <div className="mb-3">
        <input 
          type="number" 
          className="form-control" 
          placeholder="Precio" 
          value={price} 
          onChange={(e) => setPrice(e.target.value)} // Actualiza el precio del producto
          required // Campo obligatorio
        />
      </div>
      <div className="mb-3">
        <input 
          type="number" 
          className="form-control" 
          placeholder="Stock" 
          value={stock} 
          onChange={(e) => setStock(e.target.value)} // Actualiza el stock del producto
          required // Campo obligatorio
        />
      </div>
      <button type="submit" className="btn btn-warning"> {/* Botón para enviar el formulario */}
        {editingProduct ? "Actualizar Producto" : "Agregar Producto"} {/* Texto dinámico del botón */}
      </button>
    </form>
  );
}

export default ProductForm; // Exportar el componente ProductForm para su uso en otras partes de la aplicación
